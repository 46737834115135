<div id="responsive-grid">
    <figure *ngFor="let pokemon of pokemons" (click)="openPokemonDetail(pokemon.id)" class="card" [ngClass]="generateCardClass(pokemon.types)">
        <div class="card__image-container">
        <img src="{{ pokemon.sprites.front }}" alt="{{ pokemon.name }}" class="card__image">   
        </div>
        
        <figcaption class="card__caption">
            <h1 class="card__name">{{ pokemon.name }}</h1>

            <h3 class="card__type">
                {{ getPokemonType(pokemon.types) }}
            </h3>

            <!--
            <table class="card__stats">
                <tbody>
                    <tr *ngFor="let stat of pokemon.stats">
                        <th>{{ stat.stat.name | uppercase }}</th>
                        <td>{{ stat.base_stat }}</td>
                    </tr>
                </tbody>
            </table>
            -->
            
            <div class="card__abilities">
                <h4 class="card__ability">
                <span class="card__label">Height</span>
                {{ pokemon.height * 10 }} cm
                </h4>
                <h4 class="card__ability">
                <span class="card__label">Wseight</span>
                {{ (pokemon.weight * 100) / 1000 }} Kg
                </h4>
            </div>
        </figcaption>
    </figure>  
</div>