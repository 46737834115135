<div class="mat-app-background">
<nav>
  <mat-toolbar>
    <img class="poke-logo" src="../assets/icon96x96.png" />
    <span>Poke-AZ</span>
  </mat-toolbar>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>

<footer>
  <small>
    &copy; Created by Richard to Matheus & Sofia
  </small>
</footer>
</div>