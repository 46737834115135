<div class="pokestatus">
  <p class="pokestatus--name">{{ statusNames.get(stats.stat.name).name }}</p>
  <div class="pokestatus--container">
    <div
      class="pokestatus--value"
      [ngStyle]="{
        width: stats.base_stat + 'px',
        background: statusNames.get(stats.stat.name).color
      }"
    ></div>
  </div>
</div>
