<div class="card-panel blue-grey lighten-1">
  <div class="row">
    <div class="col s6">
      <dl>
        <dt class="white-text">Height</dt>
        <dd class="black-text">{{pokemon?.height | feet:'dm'}}</dd>
      </dl>
      <dl>
        <dt class="white-text">Weight</dt>
        <dd class="black-text">{{pokemon?.weight | pound:'hg':1}}</dd>
      </dl>
      <dl>
        <dt class="white-text">Base experience</dt>
        <dd class="black-text capitalize">{{pokemon?.base_experience}}</dd>
      </dl>
    </div>
    
    <div class="col s6">
      <dl>
        <dt class="white-text">Abilities</dt>
        <dd class="black-text capitalize" *ngFor="let ability of pokemon?.abilities">{{ability?.ability.name}}</dd>
      </dl>
    </div>

    <div class="col s12">
      <dl>
      <dt class="white-text">Moves</dt>
      <div class="chip capitalize" *ngFor="let move of pokemon?.moves">
        {{move?.move.name}}
      </div>
      </dl>
    </div>

  </div>
</div>